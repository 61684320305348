.card {
    border-radius: 15px;
    border: 2px;
    margin: 5px;
    border: none;
}

.card img{
    margin: 5px;
    border-radius: 15px;
}

.card :hover{
    transform: scale(1.03);
}