@import 'custom-colors.css';

ul {
  position: relative;
  display: block;
  gap: 2px;
}

ul li {
  position: relative;
  list-style: none;
  width: 70px;
  height: 70px;
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  margin: 0.5rem;
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
  ul li {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    margin: 0.5rem;
  }
}

/* Media query para pantallas medianas */
@media (min-width: 769px) and (max-width: 1024px) {
  ul li {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    margin: 0.75rem;
  }
}

ul li:hover {
  width: 120px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0);
}

ul li::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50px;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  opacity: 0;
  transition: 0.5s;
}

ul li:hover::before {
  opacity: 1;
}

ul li::after {
  content: '';
  position: absolute;
  top: 10px;
  width: 100%;
  height: 100%;
  border-radius: 60px;
  background: linear-gradient(45deg, var(--primary-color), var(--tertiary-color));
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
  filter: blur(15px);
}

ul li:hover::after {
  opacity: 0.5;
}

ul li i {
  color: black;
  font-size: 1.75em !important;
  transition: 0.5s;
  transition-delay: 0.25s;
}

ul li:hover i {
  transform: scale(0);
  font-size: 1.75em;
  transition-delay: 0.25s;
  opacity: 0;
}

ul li span {
  position: absolute;
}

ul li .titulo {
  color: black;
  font-size: 1.1em !important;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform: scale(0);
  transition: 0.5s;
  transition-delay: 0;
}

ul li:hover .titulo {
  transform: scale(1);
  transition-delay: 0.25s;
  color: black;
}
