@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 15px;
}

ul li {
  position: relative;
  list-style: none;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

ul li:hover {
  width: 110px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0);
}

ul li::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50px;
  background: linear-gradient(45deg, var(--i, var(--j)));
  opacity: 0;
  transition: 0.5s;
}

ul li:hover::before {
  opacity: 1;
}

ul li::after {
  content: '';
  position: absolute;
  top: 10px;
  width: 100%;
  height: 100%;
  border-radius: 60px;
  background: linear-gradient(45deg, var(--i), var(--j));
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
  filter: blur(15px);
}

ul li:hover::after {
  opacity: 0.5;
}

ul li i {
  color: #777;
  font-size: 1.75em !important;
  transition: 0.5s;
  transition-delay: 0.25s;
}

ul li:hover i {
  transform: scale(0);
  font-size: 1.75em;
  transition-delay: 0.15s;
}

ul li span {
  position: absolute;
}

ul li .titulo {
  color: #fff;
  font-size: 1.1em !important;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform: scale(0);
  transition: 0.3s;
  transition-delay: 0s;
}

ul li:hover .titulo {
  transform: scale(1);
  transition-delay: 0.25s;
}
@media screen and (max-width: 768px) {
  ul {
    justify-content: center;
  }
  
  ul li {
    width: 40px;
    height: 40px;
  }
  
  ul li::before {
    border-radius: 50px;
  }
  
  ul li::after {
    border-radius: 50px;
  }
  
  ul li i {
    font-size: 1.5em !important;
  }
  
  ul li .titulo {
    font-size: 1em !important;
  }
}
