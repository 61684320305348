/* brands.css */

.brands-container .slider {
  height: 50vw; /* Ajusta la altura en relación con el ancho de la pantalla */
  margin: auto;
  position: relative;
  width: 90vw; /* Ajusta el ancho en relación con el ancho de la pantalla */
  max-width: 100%; /* Garantiza que no exceda el ancho de la pantalla */
  display: grid;
  place-items: center;
  overflow: hidden;
}

.brands-container .slide-track {
  display: flex;
  width: calc(250px * 18);
  animation: scroll 40s linear infinite;
  animation-iteration-count: infinite;
}

.brands-container .slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}

.brands-container .slide {
  height: 200px;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

.brands-container .brandImage {
  width: 100%;
  transition: transform 0.5s;
}

.brands-container .brandImage:hover {
  transform: translateZ(20px);
  opacity: 0%;
}

.brands-container .slider::before,
.brands-container .slider::after {
  content: '';
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.brands-container .slider::before {
  left: 0;
  top: 0;
}

.brands-container .slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.brands-container .image-container {
  position: relative;
  width: 250px;
  height: 200px;
  perspective: 1000px;
}

.brands-container .text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);
  transition: transform 0.5s;
  opacity: 0;
}

.brands-container .image-container:hover img {
  transform: rotateY(180deg);
}

.brands-container .image-container:hover .text-overlay {
  transform: rotateY(0);
  opacity: 1;
}
